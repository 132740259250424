
export default {
  name: 'SubheadlineBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    style() {
      if (this.blok.fontweight) {
        return 'font-weight:' + this.blok.fontweight + '!important'
      }
      return {}
    },
  },
}
